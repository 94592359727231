<template>
  <div class="aboutUs">
    <div class="topImg hiden">
      <img src="../assets/imgs/aboutUs/topImg.png" alt="" />
    </div>
    <div class="companyProfile">
      <h2>公司简介</h2>
      <p class="hiden">
        我们是一家专注于效率管理的科技公司，成立于2021年01月29日。<br />
        公司以用户为中心，以提升效率为核心，致力于打造世界顶级的效率工具旨在为人们提供<br />
        更加高效、安全可靠的产品。我们是由一群有极客追求的小伙伴组建的专业团队，<br />
        秉持着对高效生活的理念，立志于通过优秀的工具来<br />
        提升世界的运转效率
      </p>
      <p class="show">
        我们是一家专注于效率管理的科技公司，<br />
        成立于2021年01月29日。公司以用户为中心，以提升效率为核心，致力于打造世界顶级的效率工具旨在为<br />
        人们提供更加高效、安全可靠的产品。<br />
        我们是由一群有极客追求的小伙伴组建的专业团队，<br />
        秉持着对高效生活的理念，立志于通过优秀的工具来<br />
        提升世界的运转效率
      </p>
      <img
        src="../assets/imgs/aboutUs/introduction_icon.png"
        class="introductionIcon show"
      />
      <img
        src="../assets/imgs/aboutUs/quotation_icon_1.png"
        class="quotationIcon1 show"
      />
      <img
        src="../assets/imgs/aboutUs/quotation_icon_2.png"
        class="quotationIcon2 show"
      />
    </div>
    <div class="corporateCulture">
      <h2 class="hiden">企业文化</h2>
      <h2 class="showBlock">我们努力践行的文化</h2>
      <div class="cultureList">
        <dl>
          <dt><img src="../assets/imgs/aboutUs/vision_icon.png" alt="" /></dt>
          <dd>愿景</dd>
          <dd>让这个世界变得井井有条</dd>
        </dl>
        <dl>
          <dt><img src="../assets/imgs/aboutUs/mission_icon.png" alt="" /></dt>
          <dd>使命</dd>
          <dd>做全球最佳的效率软件公司，用优秀的软件帮助人们进步</dd>
        </dl>
        <dl>
          <dt><img src="../assets/imgs/aboutUs/value_icon.png" alt="" /></dt>
          <dd>价值观</dd>
          <dd>
            <span>
              激情
            </span>
            <span>
              责任
            </span>
            <span>
              梦想
            </span>
            <span>
              极致
            </span>
            <span>
              创新
            </span>
            <span>
              超越
            </span>
          </dd>
        </dl>
        <dl>
          <dt><img src="../assets/imgs/aboutUs/location_icon.png" alt="" /></dt>
          <dd>定位</dd>
          <dd>专注于打造最佳的效率工具产品的科技公司</dd>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AboutUs",
};
</script>
<style lang="scss" scoped>
.topImg img {
  width: 100%;
}
.companyProfile {
  text-align: center;
  padding-top: 40px;
  color: #192646;
  margin-top: 80px;
  h2 {
    font-size: 36px;
    line-height: 1.8;
  }
  p {
    font-size: 19px;
    line-height: 2;
  }
}
.corporateCulture {
  margin-top: 80px;
  padding-bottom: 100px;
  margin-bottom: 60px;
  // border-bottom: 1px solid #eff3ff;
  h2 {
    text-align: center;
  }
}
.cultureList {
  text-align: center;
  margin-top: 80px;
  dl {
    display: inline-flex;
    width: 324px;
    height: 413px;
    box-shadow: 0px 7px 71px 0px rgba(188, 191, 210, 0.37);
    background-color: #fff;
    background-size: 100% 100%;
    flex-direction: column;
    text-align: left;
    vertical-align: top;
    padding-top: 50px;
    &:not(:first-child) {
      margin-left: 30px;
    }
    img {
      width: 82px;
      height: 82px;
      margin-left: 35px;
    }
    dd:first-of-type {
      color: #333333;
      margin-top: 20px;
      font-size: 42px;
      padding-left: 44px;
    }
    dd:last-child {
      font-size: 23px;
      line-height: 1.5;
      color: #666666;
      margin-top: 10px;
      padding-left: 44px;
      padding-right: 44px;
      span {
        display: inline-block;
      }
      span:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
  dl:nth-child(3) {
    dd:nth-of-type(2) {
      width: 100%;
    }
  }
}
@media screen and (max-width: 1200px) {
  // .cultureList {
  //   dl {
  //     padding-left: 0;
  //     dd,
  //     dt {
  //       padding-left: 46px;
  //     }
  //     dd:last-child {
  //       transform: scale(0.7) translateX(-40px) translateY(-20px);
  //       width: 270px;
  //       height: 100px;
  //       font-size: 20px;
  //     }
  //   }
  // }
}
@media screen and (max-width: 700px) {
  .cultureList {
    dl {
      width: 400px;
      height: 550px;
      dd:last-child {
        width: 100%;
        transform: scale(0.9) translateX(-15px) translateY(-10px);
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .companyProfile {
    background-color: #f9faff;
    margin-top: 0;
    padding-top: 73px;
    padding-bottom: 83px;
    position: relative;
    h2 {
      font-size: 49px;
      color: #192646;
      font-weight: 500;
    }
    p {
      font-size: 33px;
      width: 878px;
      letter-spacing: 3px;
      margin-top: 10px;
      color: #192646;
    }
  }
  .introductionIcon {
    position: absolute;
    right: 87px;
    top: 73px;
    width: 100px;
    height: 86px;
  }
  .quotationIcon1 {
    position: absolute;
    left: 41px;
    top: 155px;
    width: 38px;
    height: 26px;
  }
  .quotationIcon2 {
    position: absolute;
    right: 41px;
    bottom: 58px;
    width: 38px;
    height: 26px;
  }
  .corporateCulture {
    margin-bottom: 0px;
    padding-bottom: 65px;
    h2 {
      color: #192646;
      font-size: 49px;
      text-align: center;
    }
    .cultureList dl {
      width: 422px;
      height: 480px;
      margin: 0 0 26px;
      box-shadow: 0px 6px 64px 0px rgba(160, 165, 193, 0.2);
      border-radius: 15px;
      img {
        width: 101px;
        height: 101px;
      }
      dd:first-of-type {
        font-size: 44px;
        margin-top: 35px;
        color: #192646;
      }
      dd:last-of-type {
        transform: none;
        font-size: 31px;
        margin-top: 25px;
        color: #686E84;
      }
      &:nth-child(odd) {
        margin-right: 33px;
      }
    }
  }
}
</style>
